.hamburger-stripe {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #050505;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.hamburger-stripe-bottom {
    top: 22px;
    transform-origin: left center;
}

.hamburger-stripe-bottom-open {
    transform: rotate(-45deg);
    top: 30px;
    left: 6px;
}

.hamburger-stripe-top {
    top: 8px;
    transform-origin: left center;
}

.hamburger-stripe-top-open {
    transform: rotate(45deg);
    top: 0;
    left: 6px;
}

.navigation-icon {
    width: 42px;
    height: 32px;
    position: relative;
    transform: rotate(0deg);
    cursor: pointer;
    margin-left: 3vw;
    z-index: 125;
}
