
.description-wrapper {
    width: 80vw;
    text-align: left;
    margin: 7vh 0 7vh 0
}

.description-wrapper h3 {
    text-align: center;
    margin-bottom: 7vh;
    font-family: 'Poppins', sans-serif;
}

.experience-content-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 9.5vh;
}

.experience-wrapper h3 {
    margin: 7vh 0 7vh 0;
}

.link-wrapper {
    display: flex;
    flex-direction: column;
    width: 50vw;
    justify-content: center;
}

.link-wrapper a {
    text-decoration: none;
    color: #050505;
    font-family: 'Dancing Script', sans-serif;
    font-size: 4vh;
    margin: 2vh 0 2vh 0
}

.port-quote {
    padding-bottom: 7vh;
}

.portfolio-title {
    background: #ffffff;
    margin-top: 7vh;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolio-title h3 {
    font-size: 3vh;
}

.project-logo {
    width: 100vw;
    object-fit: cover;
}

.project-preview {
    width: 30vw;
    border: solid 2px #186CA0;
    margin-left: 19vw;
}

.project-preview-wrapper {
    width: 50vw;
}

.project-wrapper {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slogan-wrapper {
    background: #050505;
    width: 100vw;
    padding: 5vh 0 5vh 0;
    font-size: 5vh;
}

.tech-category {
    font-family: 'Dancing Script', sans-serif;
    font-size: 5vh;
    margin: 3vh 0 3vh 0;
    font-weight: bold;
}

.tech-categories-column {
    display: flex;
    flex-direction: column;
}

.tech-title {
    color: #DAECF5;
    font-size: 2.3vh;
}

.tech-wrapper {
    background: #050505;
    width: 100vw;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 9.5vh 0 9.5vh 0;
}

.tech-wrapper span {
    line-height: 1.3;
    font-weight: 2.3vh;
}


@media all and (min-width: 700px) {
    .experience-wrapper {
        height: 75vh;
    }

    .experience-wrapper h3 {
        font-size: 5vh;
        margin: 7vh 0 7vh 0;
    }

    .link-wrapper {
        width: 25vw;
    }

    .tech-categories-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 2vh 15vw 0 15vw;
    }

    .project-preview {
        width: 25vw;
        margin: 0;
        height: 50vh;
        object-fit: cover;
    }

    .project-preview-wrapper {
        margin: 0;
        width: 25vw;
    }
}

@media all and (min-width: 1000px) {
    .description-wrapper {
        width: 60vw;
    }

    .link-wrapper {
        width: 20vw;
    }


    .project-preview {
        width: 20vw;
    }

    .portfolio-title {
        height: 7vh;
        padding-top: 9.5vh;
    }

    .project-logo {
        width: 30vw;
        border: solid 2px #050505;
        margin-top: 9.5vh;
    }
}

@media all and (min-width: 1200px) {
    .link-wrapper {
        width: 15vw;
    }

    .project-preview {
        width: 15vw;
    }
}
