
.services-contact-me {
    text-decoration: none;
    color: #DAECF5;
    margin-top: 2vh;
    font-size: 2.3vh;
}
.services-wrapper {
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    color: #ffffff;
}

.services-wrapper h3 {
    font-size: 3.7vh;
    color: #ffffff;
    margin: 1vh 10vh 0 10vh;
    font-family: 'Lato'
}

.services-wrapper span {
    font-size: 2.3vh;
    margin: 1vh 0 1vh 0;
    color: #DAECF5;
}

@media all and (min-width: 800px) {
    .services-wrapper h3 {
        font-size: 4vh;
    }

    .services-wrapper span {
        margin: 1vh 0 .4vh 0;
    }

    .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .text-container h3 {
        width: 25vw;
    }
}
