@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Lato:wght@300&family=Poppins:wght@300&display=swap);

.arrow {
    height: 7vh;
    cursor: pointer;
    z-index: 35;
    margin: 0;
    padding: 0;
}

.carousel {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 25vh;
    margin-top: 2vh;
}

.carousel-text {
    width: 60vw;
    font-size: 2.3vh;
    margin: 0 5vw 0 5vw;
}

.carousel-title, .carousel-title-large {
    color: #186CA0;
    font-weight: bold;
    margin-top: 7vh;
}

.carousel-title-large {
    font-size: 3vh;
}

.carousel-wrapper {
    background: #ffffff;
    width: 100vw;
    height: 45vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.music-icons {
    margin-top: 2vh;
}

.music-icons a {
    margin: 0 1.5vw 0 1.5vw;
}

.project {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.project#caption {
    position: relative;
}
  
.project#caption .text {
    position: absolute;   
    z-index: 10;
    opacity: 0;
    transition: all 0.8s ease; 
    margin: 0;    
    color: #ffffff;
    font-size: 4vh;
    font-family: 'Poppins', sans-serif;     
}

.project#caption:hover .text {
    opacity: 1;
    width: 60vw;
    height: 32vw;
    background: rgb(5, 5, 5);
    background: rgba(5, 5, 5, .84);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.project-image {
    width: 60vw;
    border: solid 2px #050505;
    position: relative;
}
  

@media all and (min-width: 600px) {
    .carousel-text, .project-image {
        width: 40vw;
    }

    .project#caption:hover .text {
        width: 40vw;
        height: 21vw;
    }
}

@media all and (min-width: 800px) {
    .carousel-text {
        width: 25vw;
    }

    .project-image {
        width: 25vw;
    }

    .project#caption:hover .text {
        width: 25vw;
        height: 13.5vw;
    }
}

@media all and (min-width: 1100px) {
    .project-image {
        width: 20vw;
    }

    .project#caption:hover .text {
        width: 20vw;
        height: 10.5vw;
    }
}


.about-photo {
    width: 100vw;
    object-fit: cover;
    height: 60vh;
}

.about-text-wrapper {
    width: 80vw;
    text-align: left;
    line-height: 1.5;
    font-size: 2.3vh;
    margin: 3vh 0 3vh 0;
}

.about-work-together {
    text-decoration: none;
    color: #186CA0;
    font-weight: bold;
}

.about-work-together-wrapper {
    text-align: center;
    margin: 3vh 0 1vh 0;
}

.about-wrapper {
    width: 100vw;
    background: #ffffff;
    margin-top: 5vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.enough-about-me-wrapper {
    height: 28vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.enough-about-me-wrapper {
    height: 28vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.enough-about-me-wrapper h2 {
    margin-top: 2vh;
}

.mission-statement {
    color: white;
    text-align: left;
    height: 50vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.mission-statement li {
    width: 60vw;
    margin-top: 1.5vh;
    font-size: 2.3vh;
}

.mission-title {
    color: #DAECF5;
    margin-top: 2vh;
    font-size: 3.4vh;
    font-weight: bold;
}

@media all and (min-width: 850px) {
    .about-photo {
        width: 60vw;
        height: 80vh;
        margin-top: 9.5vh;
        border: solid 2px #050505;
    }
    .mission-statement li {
        width: 55vw;
    }
}

@media all and (min-width: 900px) {
    .mission-statement li {
        width: 45vw;
    }
}

@media all and (min-width: 1000px) {
    .mission-statement li {
        width: 40vw;
    }
}


button {
    margin: 3vh 3vw 3vh 3vw; 
    font-family: 'Poppins', sans-serif;
    width: 15vw;
    height: 5vh;
    font-size: 2vh;
    background: #ffffff;
    border: solid 1px #050505;
}

button:hover {
    color: #ffffff;
    background: #050505;
}

.contact-form h3 {
    margin: 3vh 0 0 0;
    font-size: 5.5vh;
}

.contact-form h4 {
    font-family: 'Dancing Script' , sans-serif;
    font-size: 3.5vh;
    margin: 2vh 0 3vh 0;
}

.contact-social {
    margin-bottom: 3vh;
}

.contact-social a {
    margin: 1.5vw;
}

.farewell {
    margin: 9.5vh 0 9.5vh 0;
}

.farewell h4 {
    font-size: 5vh;
    margin: 0;
}

form, .contact-form {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items:center;
	        align-items:center;
}

.form-wrapper {
	text-align:left;
}

input {
    height: 4vh;
}

input, textarea {
    padding: 1vh 0 1vh 0;
    display: -webkit-flex;
    display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	background-color: #DAECF5;
    width: 50vw;
	border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
	font-size: 2.3vh;
	text-indent: 3vw;
	margin: 1vh 0 1vh 0;
	color: #050505;
    font-family: 'Lato', sans-serif;
}

textarea {
    height: 10vh;
}

input:focus, textarea:focus {
	outline: none;
	background-color: #EBFEFF;
}

label {
	font-size: 2.3vh;
	margin-left: 3vw;
	font-weight: bold;
	color: #186CA0;
}

@media all and (max-width: 800px) {
    button {
        margin: 4vh 3vw 4.5vh 3vw;
    }
    .contact-form h3 {
        margin: 5vh 0 0 0;
    }

    input, textarea {
        width: 100vw;;
    }
}

.recent-project-container {
    background: #ffffff;
}

.recent-project-preview {
    width: 60vw;
    border: solid 2px #186CA0;
}

.recent-project-tech-wrapper {
    margin-bottom: 7vh;
    font-size: 2.2vh;
}


.recent-project-tech-wrapper p {
    font-size: 2.3vh;
}

.recent-project-text-wrapper h3 {
    font-family: 'Poppins', sans-serif;
}

.recent-projects-title-wrapper {
    background: #ffffff;
    height: 22vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.recent-project-wrapper {
    width: 80vw;
}

.recent-project-wrapper a {
    text-decoration: none;
    font-family: 'Dancing Script', sans-serif;
    margin: 0 3vw 0 3vw;
    font-size: 3vh;
    color: #050505;
}

.recent-project-wrapper h3 {
    font-size: 5vh;
    margin: 4vh 0 3vh 0;
}

.recent-project-wrapper h4 {
    color: #186CA0;
    font-size: 2.3vh;
}

.recent-project-wrapper p, .recent-project-tech-wrapper {
    text-align: left;
}

@media all and (min-width: 500px) {
    .recent-project-preview {
       width: 22vh;
    }  
}

@media all and (min-width: 800px) {   
    .recent-project-preview {
        width: 15vw;
        height: 43vh;
        object-fit: cover;
        margin-right: 7vw;
    }  

    .recent-project-details-wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        margin-bottom: 5vh;
    }

    .recent-project-text-wrapper {
        width: 50vw;
    }

    .recent-projects-title-wrapper {
        height: 30vh;
    }
}


.services-contact-me {
    text-decoration: none;
    color: #DAECF5;
    margin-top: 2vh;
    font-size: 2.3vh;
}
.services-wrapper {
    height: 40vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;;
    color: #ffffff;
}

.services-wrapper h3 {
    font-size: 3.7vh;
    color: #ffffff;
    margin: 1vh 10vh 0 10vh;
    font-family: 'Lato'
}

.services-wrapper span {
    font-size: 2.3vh;
    margin: 1vh 0 1vh 0;
    color: #DAECF5;
}

@media all and (min-width: 800px) {
    .services-wrapper h3 {
        font-size: 4vh;
    }

    .services-wrapper span {
        margin: 1vh 0 .4vh 0;
    }

    .text-container {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        text-align: center;
    }

    .text-container h3 {
        width: 25vw;
    }
}


.go-back {
    font-size: 2.3vh;
    color: #DAECF5;
}

.not-found-route {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 73vh;
}

.not-found-wrapper {
    width: 60vw;
}

.not-found-wrapper h2 {
    margin-bottom: 3vh;
}


.description-wrapper {
    width: 80vw;
    text-align: left;
    margin: 7vh 0 7vh 0
}

.description-wrapper h3 {
    text-align: center;
    margin-bottom: 7vh;
    font-family: 'Poppins', sans-serif;
}

.experience-content-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 9.5vh;
}

.experience-wrapper h3 {
    margin: 7vh 0 7vh 0;
}

.link-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 50vw;
    -webkit-justify-content: center;
            justify-content: center;
}

.link-wrapper a {
    text-decoration: none;
    color: #050505;
    font-family: 'Dancing Script', sans-serif;
    font-size: 4vh;
    margin: 2vh 0 2vh 0
}

.port-quote {
    padding-bottom: 7vh;
}

.portfolio-title {
    background: #ffffff;
    margin-top: 7vh;
    height: 20vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.portfolio-title h3 {
    font-size: 3vh;
}

.project-logo {
    width: 100vw;
    object-fit: cover;
}

.project-preview {
    width: 30vw;
    border: solid 2px #186CA0;
    margin-left: 19vw;
}

.project-preview-wrapper {
    width: 50vw;
}

.project-wrapper {
    background: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.slogan-wrapper {
    background: #050505;
    width: 100vw;
    padding: 5vh 0 5vh 0;
    font-size: 5vh;
}

.tech-category {
    font-family: 'Dancing Script', sans-serif;
    font-size: 5vh;
    margin: 3vh 0 3vh 0;
    font-weight: bold;
}

.tech-categories-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.tech-title {
    color: #DAECF5;
    font-size: 2.3vh;
}

.tech-wrapper {
    background: #050505;
    width: 100vw;
    color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 9.5vh 0 9.5vh 0;
}

.tech-wrapper span {
    line-height: 1.3;
    font-weight: 2.3vh;
}


@media all and (min-width: 700px) {
    .experience-wrapper {
        height: 75vh;
    }

    .experience-wrapper h3 {
        font-size: 5vh;
        margin: 7vh 0 7vh 0;
    }

    .link-wrapper {
        width: 25vw;
    }

    .tech-categories-wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin: 2vh 15vw 0 15vw;
    }

    .project-preview {
        width: 25vw;
        margin: 0;
        height: 50vh;
        object-fit: cover;
    }

    .project-preview-wrapper {
        margin: 0;
        width: 25vw;
    }
}

@media all and (min-width: 1000px) {
    .description-wrapper {
        width: 60vw;
    }

    .link-wrapper {
        width: 20vw;
    }


    .project-preview {
        width: 20vw;
    }

    .portfolio-title {
        height: 7vh;
        padding-top: 9.5vh;
    }

    .project-logo {
        width: 30vw;
        border: solid 2px #050505;
        margin-top: 9.5vh;
    }
}

@media all and (min-width: 1200px) {
    .link-wrapper {
        width: 15vw;
    }

    .project-preview {
        width: 15vw;
    }
}


.Footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    height: 10vh;
    width: 100vw;
    left: 0; right: 0; bottom: 0;
    background-color: #E9EAEC;
}

.footer-copy {
    color: #050505;
    font-size: 1.8vh;
    margin-left: 3vw;
}

.hamburger-stripe {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #050505;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.hamburger-stripe-bottom {
    top: 22px;
    -webkit-transform-origin: left center;
            transform-origin: left center;
}

.hamburger-stripe-bottom-open {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 30px;
    left: 6px;
}

.hamburger-stripe-top {
    top: 8px;
    -webkit-transform-origin: left center;
            transform-origin: left center;
}

.hamburger-stripe-top-open {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 0;
    left: 6px;
}

.navigation-icon {
    width: 42px;
    height: 32px;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    cursor: pointer;
    margin-left: 3vw;
    z-index: 125;
}


.Header {
    height: 20vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: #FFEED6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
}

.invisible-icon {
    width: 42px;
    height: 32px;
    position: relative;
    margin-right: 3vw;
}

.invisible-stripe {
    display: block;
    height: 2px;
    width: 100%;
    color: #FFEED6;
}

.job-title {
    font-size: 2.3vh;
    margin: 0;
    padding: 0;
}

.logo {
    text-decoration: none;
    color: #050505;
    font-size: 4vh;
    margin: 0;
    padding: 0;
}

.logo-blue {
    color: #186ca0;
}

.logo-wrapper {
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}


@media all and (max-width: 300px) {
    .logo {
        font-size: 3.7vh;
    }
}


@-webkit-keyframes slideInFromTop {
    from {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
  
@keyframes slideInFromTop {
    from {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
  
.navigation {
    top: 20vh;
    height: 50vh;
    width: 100vw;
    background-color: #FFEED6;
    position: fixed;
    text-align: left;
    -webkit-animation: .5s ease-in-out 0s 1 slideInFromTop;
            animation: .5s ease-in-out 0s 1 slideInFromTop;
    z-index: 50;
    overflow: hidden;
    border-radius: 0 0 20px 20px;
}

.navigation-link-wrapper {
    width: 85vw;
    color: #050505;
    text-decoration: none;
    font-size: 2.5vh;
    font-weight: bold;
    padding: 1.8vh 0 1.8vh 0;
    border-bottom: solid 1px;
    height: 3vh;
}

.navigation-link-wrapper:hover {
    color: #186CA0;
}

.navigation-menu-wrapper {
    border-top: solid 1px #050505;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.navigation-social-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: none;
}

.navigation-social-wrapper img{
    padding: 0;
    margin: 0 1.5vw 0 1.5vw;
}

.social-icon {
    height: 4vh;
}

.social-text {
    font-family: 'Dancing Script', sans-serif;
    font-size: 3.5vh;
    margin: 5vh 0 2vh 0;
}


.App {
    text-align: center;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 27vh;
    width: 100vw;
    color: #050505;
    min-height: 100%;
    position: relative;
    background: #050505;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.error-message {
	  color: #BA3F1D;
	  font-weight: bold;
}

:focus {
	  outline: 0px
}

:focus-visible {
	  outline: 5px solid #2683be;
}

h1 {
	font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
}

h2 {
    font-size: 6vh;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Dancing Script';
    margin: 0;
}

h3 {
    color: #186CA0;
    font-size: 5vh;
    margin: 0;
}

.main-photo {
    width: 100vw;
    object-fit: cover;
}

.main-text-wrapper {
    width: 100vw;
    background: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.main-wrapper {
    width: 100vw;
    margin-top: 7vh;
    padding: 0;
}

.quote {
    color: white;
    font-family: 'Dancing Script', sans-serif;
    width: 75vw;
    padding: 0;
    margin: 0;
}

.quote2 {
    padding-bottom: 2vh;
}

.quote-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.quote-container h2 {
    margin-bottom: 2vh;
}

.quote-credit {
    font-size: 2.7vh;
}

.text-container {
    width: 60vw;
    height: 40vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

@media all and (min-width: 500px) {
    .quote {
        width: 60vw;
    }
}

@media all and (min-width: 800px) {
    h3 {
        font-size: 7vh;
    }

    .main-wrapper {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        height: 80vh;
    }

    .main-photo {
        width: 50vw;
    }

    .main-text-wrapper {
        width: 50vw;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        text-align: center;
    }
}

