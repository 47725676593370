
.arrow {
    height: 7vh;
    cursor: pointer;
    z-index: 35;
    margin: 0;
    padding: 0;
}

.carousel {
    display: flex;
    align-items: center;
    height: 25vh;
    margin-top: 2vh;
}

.carousel-text {
    width: 60vw;
    font-size: 2.3vh;
    margin: 0 5vw 0 5vw;
}

.carousel-title, .carousel-title-large {
    color: #186CA0;
    font-weight: bold;
    margin-top: 7vh;
}

.carousel-title-large {
    font-size: 3vh;
}

.carousel-wrapper {
    background: #ffffff;
    width: 100vw;
    height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.music-icons {
    margin-top: 2vh;
}

.music-icons a {
    margin: 0 1.5vw 0 1.5vw;
}

.project {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project#caption {
    position: relative;
}
  
.project#caption .text {
    position: absolute;   
    z-index: 10;
    opacity: 0;
    transition: all 0.8s ease; 
    margin: 0;    
    color: #ffffff;
    font-size: 4vh;
    font-family: 'Poppins', sans-serif;     
}

.project#caption:hover .text {
    opacity: 1;
    width: 60vw;
    height: 32vw;
    background: rgb(5, 5, 5);
    background: rgba(5, 5, 5, .84);
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-image {
    width: 60vw;
    border: solid 2px #050505;
    position: relative;
}
  

@media all and (min-width: 600px) {
    .carousel-text, .project-image {
        width: 40vw;
    }

    .project#caption:hover .text {
        width: 40vw;
        height: 21vw;
    }
}

@media all and (min-width: 800px) {
    .carousel-text {
        width: 25vw;
    }

    .project-image {
        width: 25vw;
    }

    .project#caption:hover .text {
        width: 25vw;
        height: 13.5vw;
    }
}

@media all and (min-width: 1100px) {
    .project-image {
        width: 20vw;
    }

    .project#caption:hover .text {
        width: 20vw;
        height: 10.5vw;
    }
}
