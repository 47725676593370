@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Lato:wght@300&family=Poppins:wght@300&display=swap');

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 27vh;
    width: 100vw;
    color: #050505;
    min-height: 100%;
    position: relative;
    background: #050505;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.error-message {
	  color: #BA3F1D;
	  font-weight: bold;
}

:focus {
	  outline: 0px
}

:focus-visible {
	  outline: 5px solid #2683be;
}

h1 {
	font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
}

h2 {
    font-size: 6vh;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Dancing Script';
    margin: 0;
}

h3 {
    color: #186CA0;
    font-size: 5vh;
    margin: 0;
}

.main-photo {
    width: 100vw;
    object-fit: cover;
}

.main-text-wrapper {
    width: 100vw;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-wrapper {
    width: 100vw;
    margin-top: 7vh;
    padding: 0;
}

.quote {
    color: white;
    font-family: 'Dancing Script', sans-serif;
    width: 75vw;
    padding: 0;
    margin: 0;
}

.quote2 {
    padding-bottom: 2vh;
}

.quote-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quote-container h2 {
    margin-bottom: 2vh;
}

.quote-credit {
    font-size: 2.7vh;
}

.text-container {
    width: 60vw;
    height: 40vh;
    display: flex;
    align-items: center;
}

@media all and (min-width: 500px) {
    .quote {
        width: 60vw;
    }
}

@media all and (min-width: 800px) {
    h3 {
        font-size: 7vh;
    }

    .main-wrapper {
        display: flex;
        flex-direction: row;
        height: 80vh;
    }

    .main-photo {
        width: 50vw;
    }

    .main-text-wrapper {
        width: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
